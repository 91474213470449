import Glide from '@glidejs/glide';

function initCarousel(el) {
  const carousel = el;
  if (!(carousel instanceof HTMLElement)) return;

  const slides = carousel.querySelector('.glide__slides');
  if (!(slides instanceof HTMLElement)) return;

  const ribbon = document.querySelector('.js-sitewide-ribbon');
  if (ribbon instanceof HTMLElement) {
    carousel.classList.add('ribbon--active');
  } else {
    carousel.classList.remove('ribbon--active');
  }

  if (slides.firstChild) {
    const glide = new Glide(carousel, {
      type: 'carousel',
      perView: 1,
      gap: 0,
      keyboard: true,
    });
    glide.mount();
  }
}

const heroCarousels = Array.from(
  document.querySelectorAll('.js-hero-carousel'),
);
heroCarousels.forEach(initCarousel);
